import { LitElement, html } from 'lit';
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import store from './store';
import { getTournamentsByMonth } from './actions/tournamentActions';
import { selectTournamentsByMonth } from './selectors/tournamentSelectors';

export default class TournamentsByMonth extends LitElement {
  static properties = {
    location: {state: true},
    tournaments: {state: true},
    unsubscribe: {state: true}
  };

  connectedCallback() {
    super.connectedCallback();
    const { date } = this.location.params;
    const [ month, year ] = date.split(' ');

    this.unsubscribe = store.subscribe(() => {
      this.tournaments = selectTournamentsByMonth(month, year)(store.getState());
    });
    store.dispatch(getTournamentsByMonth(month, year));
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.unsubscribe();
  }

  willUpdate(changedProperties) {
    if (changedProperties.has('location')) {
      const { date } = this.location.params;
      const [ month, year ] = date.split(' ');

      this.tournaments = selectTournamentsByMonth(month, year)(store.getState());
      store.dispatch(getTournamentsByMonth(month, year));
    }
  }

  render() {
    if (this.tournaments) {
      return html`<dl>
        ${this.tournaments.map(tournament => html`<dt>${tournament.name}</dt><dd>${unsafeHTML(tournament.description)}</dd>`)}
        </dl>`;
    } else {
      const { date } = this.location.params;
      const [ month, year ] = date.split(' ');

      return html`
        <p>No tournaments found during ${month}, ${year}.</p>`;
    }
  }
}

customElements.define('tournaments-by-month', TournamentsByMonth);
