export const GET_TOURNAMENTS_BY_MONTH_SUCCESS = 'GET_TOURNAMENTS_BY_MONTH_SUCCESS';
export const GET_TOURNAMENTS_BY_REGION_SUCCESS = 'GET_TOURNAMENTS_BY_REGION_SUCCESS';
export const GET_TOURNAMENTS_ERROR = 'GET_TOURNAMENTS_ERROR';

const url = 'https://public-api.wordpress.com/rest/v1.1/sites/pushpushlook.wordpress.com/posts?category=tournaments&order_by=title&order=ASC&fields=title,content';

const getTournamentsByMonthSuccess = (month, year, posts) => ({
  type: GET_TOURNAMENTS_BY_MONTH_SUCCESS,
  month,
  year,
  posts
});

const getTournamentsByRegionSuccess = (region, posts) => ({
  type: GET_TOURNAMENTS_BY_REGION_SUCCESS,
  region,
  posts
});

export const getTournamentsByMonth = (month, year) => 
  async (dispatch, getState) => {
    if (!((getState()?.tournaments?.byMonth || {})[year] || {})[month]) {
      const posts = await fetch(`${url}&tag=${month}-${year}`)
        .then(response => response.json())
        .then(responseJson => responseJson.posts);

      dispatch(getTournamentsByMonthSuccess(month, year, posts));
    }
  };

export const getTournamentsByRegion = region => 
  async (dispatch, getState) => {
    if (!(getState()?.tournaments?.byRegion || {})[region]) {
      const posts = await fetch(`${url}&tag=${region}`)
        .then(response => response.json())
        .then(responseJson => responseJson.posts);

      dispatch(getTournamentsByRegionSuccess(region, posts));
    }
  };
