export const GET_PROGRAMS_BY_REGION_SUCCESS = 'GET_PROGRAMS_BY_REGION_SUCCESS';
export const GET_PROGRAMS_ERROR = 'GET_PROGRAMS_ERROR';

const url = 'https://public-api.wordpress.com/rest/v1.1/sites/pushpushlook.wordpress.com/posts?category=programs&order_by=title&order=ASC&fields=title,content';

const getProgramsByRegionSuccess = (region, posts) => ({
  type: GET_PROGRAMS_BY_REGION_SUCCESS,
  region,
  posts
});

export const getProgramsByRegion = region => 
  async (dispatch, getState) => {
    if (!(getState()?.programs || {})[region]) {
      const posts = await fetch(`${url}&tag=${region}`)
        .then(response => response.json())
        .then(responseJson => responseJson.posts);

      dispatch(getProgramsByRegionSuccess(region, posts));
    }
  };
