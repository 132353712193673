import { Router } from '@vaadin/router';
import './TournamentsByRegion';
import './TournamentsByMonth';
import './ProgramsByRegion';

const router = new Router(document.querySelector('body > .container'));

router.setRoutes([
  { path: '/tournaments/byregion/:region', component: 'tournaments-by-region' },
  { path: '/tournaments/bymonth/:date', component: 'tournaments-by-month' },
  { path: '/programs/byregion/:region', component: 'programs-by-region' }
]);
