import {
  GET_TOURNAMENTS_BY_MONTH_SUCCESS,
  GET_TOURNAMENTS_BY_REGION_SUCCESS
} from '../actions/tournamentActions';

const initialState = {
  byMonth: {},
  byRegion: {}
};

const getTournamentFromPost = post => {
  const { title, content } = post;
  const [, date, name] = /^(\d+-\d+-\d+) (.*)$/.exec(title);

  return {
    name,
    date: new Date(date),
    description: content
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
  case GET_TOURNAMENTS_BY_MONTH_SUCCESS: {
    const { month, year, posts } = action;

    return posts.length ? {
      ...state,
      byMonth: {
        ...state.byMonth,
        [year]: {
          ...(state.byMonth[year] || []),
          [month]: posts.map(getTournamentFromPost)
        }
      }
    } : state;
  }
  case GET_TOURNAMENTS_BY_REGION_SUCCESS: {
    const { region, posts } = action;

    return posts.length ? {
      ...state,
      byRegion: {
        ...state.byRegion,
        [region]: posts.map(getTournamentFromPost)
      }
    } : state;
  }
  default:
    return state;
  }
};
