import { LitElement, html } from 'lit';
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import store from './store';
import { getTournamentsByRegion } from './actions/tournamentActions';
import { selectTournamentsByRegion } from './selectors/tournamentSelectors';

export default class TournamentsByRegion extends LitElement {
  static properties = {
    location: {state: true},
    tournaments: {state: true},
    unsubscribe: {state: true}
  };

  connectedCallback() {
    super.connectedCallback();
    const { region } = this.location.params;

    this.unsubscribe = store.subscribe(() => {
      this.tournaments = selectTournamentsByRegion(region)(store.getState());
    });
    store.dispatch(getTournamentsByRegion(region));
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.unsubscribe();
  }

  willUpdate(changedProperties) {
    if (changedProperties.has('location')) {
      const { region } = this.location.params;

      this.tournaments = selectTournamentsByRegion(region)(store.getState());
      store.dispatch(getTournamentsByRegion(region));
    }
  }

  render() {
    if (this.tournaments) {
      return html`<dl>
        ${this.tournaments.map(tournament => html`<dt>${tournament.name}</dt><dd>${unsafeHTML(tournament.description)}</dd>`)}
        </dl>`;
    } else {
      return html`
        <p>No tournaments found in ${this.location.params.region}.</p>`;
    }
  }
}

customElements.define('tournaments-by-region', TournamentsByRegion);
