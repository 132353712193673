import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import tournamentReducer from './reducers/tournamentReducer';
import programReducer from './reducers/programReducer';

export default createStore(
  combineReducers({
    tournaments: tournamentReducer,
    programs: programReducer
  }),
  applyMiddleware(thunk)
);
