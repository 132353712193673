import { LitElement, html } from 'lit';
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import store from './store';
import { getProgramsByRegion } from './actions/programActions';
import { selectProgramsByRegion } from './selectors/programSelectors';

export default class ProgramsByRegion extends LitElement {
  static properties = {
    location: {state: true},
    programs: {state: true},
    unsubscribe: {state: true}
  };

  connectedCallback() {
    super.connectedCallback();
    const { region } = this.location.params;

    this.unsubscribe = store.subscribe(() => {
      this.programs = selectProgramsByRegion(region)(store.getState());
    });
    store.dispatch(getProgramsByRegion(region));
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.unsubscribe();
  }

  willUpdate(changedProperties) {
    if (changedProperties.has('location')) {
      const { region } = this.location.params;

      this.programs = selectProgramsByRegion(region)(store.getState());
      store.dispatch(getProgramsByRegion(region));
    }
  }

  render() {
    if (this.programs) {
      return html`<dl>
        ${this.programs.map(program => html`<dt>${program.name}</dt><dd>${unsafeHTML(program.description)}</dd>`)}
        </dl>`;
    } else {
      return html`
        <p>No progrms found in ${this.location.params.region}.</p>`;
    }
  }
}

customElements.define('programs-by-region', ProgramsByRegion);
