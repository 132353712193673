import {
  GET_PROGRAMS_BY_REGION_SUCCESS
} from '../actions/programActions';

const getProgramFromPost = post => {
  return {
    name: post.title,
    description: post.content
  };
};

export default (state = {}, action) => {
  switch (action.type) {
  case GET_PROGRAMS_BY_REGION_SUCCESS: {
    const { region, posts } = action;

    return posts.length ? {
      ...state,
      [region]: posts.map(getProgramFromPost)
    } : state;
  }
  default:
    return state;
  }
};
